import { Ref, ref, inject } from 'vue'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { TVehicleState } from '@/components/cards/VehicleForm.vue'
import { useInventoryStore } from '@/stores/inventory.ts'
import { useApplicationStore } from '@/stores/application.ts'
import { useRouter } from 'vue-router'
import { Toast } from '@/models/toaster/toast'
import { useDealerStore } from '@/stores/dealer/dealer'

export type TEditMileageResponse = { vehicle: Vehicle | undefined; milesDifference: number }

type TUsedEditVehicleMileageReturn = {
    editMileage: (editedVehicle: TVehicleState, currentVehicle?: Vehicle) => Promise<TEditMileageResponse>
    returnToInventory: (title: string, message: string) => Promise<void>
    loading: Ref<boolean>
}

export function useEditVehicleMileage(): TUsedEditVehicleMileageReturn {
    const router = useRouter()
    const applicationStore = useApplicationStore()
    const dealerStore = useDealerStore()
    const inventoryStore = useInventoryStore()
    const loading: Ref<boolean> = ref(false)
    const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}

    async function editMileage(editedVehicle: TVehicleState, currentVehicle?: Vehicle): Promise<TEditMileageResponse> {
        const milesDiff: number = Number(editedVehicle.mileage) - Number(currentVehicle?.miles)
        if (!milesDiff) {
            return { vehicle: currentVehicle, milesDifference: milesDiff }
        }

        loading.value = true

        const updatedVehicle = await inventoryStore.updateVehicle(
            {
                miles: Number(editedVehicle.mileage),
                zipcode: inventoryStore.dealerZipCode ?? '',
                year: Number(editedVehicle.year),
                vin: currentVehicle?.vin || '',
            },
            currentVehicle?.vin || '',
            applicationStore.account?.id,
        )
        loading.value = false

        return { vehicle: updatedVehicle, milesDifference: milesDiff }
    }

    async function returnToInventory(title: string, message: string): Promise<void> {

        popToastLocal({
            title: title,
            message: message,
            timer: 5000,
            location: 'top-center',
        })

        await router.push({
            name: 'Inventory',
            params: { dealer_id: dealerStore.dealer.id, account_id: applicationStore.application?.account_id },
        })
    }

    return { editMileage, returnToInventory, loading }
}
