<template>
    <v-card class="!px-3 min-w-[220px] w-full bg-background">
        <v-table class="bg-transparent">
            <thead>
                <tr>
                    <th class="!h-0 pt-4 pb-0 !border-none text-vuetify-text-secondary text-xs" v-for="cols in columns">
                        <pre class="font-inter">{{ cols }}</pre>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(rate, key) in rates"
                    :key="key"
                    :data-testid="`apr_menu_${key}`"
                    :class="{ 'bg-vuetify-bg-surface-300': selected == key }"
                    @click="selected = String(key)">
                    <td class="w-12">
                        <v-radio-group v-model="selected" hide-details color="teal-lighten-1">
                            <v-radio :value="key" hide-details></v-radio>
                        </v-radio-group>
                    </td>
                    <td v-if="String(key) !== 'custom'">{{ convertToPercent(rate) }}%</td>
                    <td v-else class="w-32">
                        <p v-if="selected !== 'custom'" class="capitalize">{{ rate ? `${rate}%` : key }}</p>
                        <v-form v-else v-model="validRate">
                            <currency-input
                                v-model="rates.custom"
                                data-testid="apr_menu_custom_input"
                                @update:model-value="debounceCustomFees"
                                :vuetify-props="{
                                    label: customMessage,
                                    variant: 'filled',
                                    persistentPlaceholder: true,
                                    density: 'compact',
                                    hideDetails: true,
                                    rules: [validations.overMax, validations.underMin],
                                    placeholder: '00.00',
                                    suffix: '%',
                                    bgColor: 'white',
                                }"
                                :options="{
                                    currency: 'USD',
                                    currencyDisplay: CurrencyDisplay.hidden,
                                    precision: 2,
                                    valueRange: {
                                        max: 99,
                                    },
                                }">
                            </currency-input>
                        </v-form>
                    </td>
                    <template v-if="isVehicleSelected">
                        <td v-for="(fee, feeKey) in fees[key]" :key="`${key}-${feeKey}`">
                            <template v-if="loading[key]">
                                <div class="text-center">
                                    <v-progress-circular indeterminate size="14" color="primary" />
                                </div>
                            </template>
                            <template v-else>
                                <div :data-testid="`apr_menu_${feeKey}`" v-if="rate">
                                    <div v-if="String(key) !== 'custom'">${{ fee.toFixed(2) }}</div>
                                    <div v-else>
                                        <div v-if="fee">${{ fee.toFixed(2) }}</div>
                                    </div>
                                </div>
                            </template>
                        </td>
                    </template>
                </tr>
            </tbody>
        </v-table>
        <v-card-item v-if="isVehicleSelected" class="!p-0 pt-1">
            <p class="bg-vuetify-bg-surface-200 p-1 text-info max-w-[450px]">
                The rate you select will adjust your inventory and affect your acquisition fee
            </p>
        </v-card-item>
        <v-card-actions class="px-0 pt-0">
            <v-row>
                <v-col class="pr-1">
                    <v-btn
                        block
                        variant="outlined"
                        color="primary"
                        class="!text-xs !tracking-normal text-none grow"
                        @click="closeMenu"
                        >Close
                    </v-btn>
                </v-col>
                <v-col class="pl-1">
                    <v-btn
                        block
                        variant="flat"
                        data-testid="apr_menu_confirm_btn"
                        :disabled="!validRate && selected === 'custom'"
                        color="primary"
                        class="!text-xs !tracking-normal text-none grow"
                        @click="handleConfirm"
                        >Apply
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted, reactive, Ref, ref, toRefs } from 'vue'
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import { ApplicationDeal } from '@/models/Application/ApplicationDeal.ts'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { CurrencyDisplay } from 'vue-currency-input'
import { convertToPercent } from '@/helpers/sanitizers.ts'
import { useApplicationStore } from '@/stores/application.ts'
import { debounce, round } from 'lodash'
import { MAX_DISCOUNT_PERCENTAGE, MIN_LOAN_AMOUNT } from '@/constants/amounts.ts'
import { DealStructure } from '@/models/Deal/DealStructure.ts'
import { buildVehicleDealPayload } from '@/helpers/payloads.ts'
import { useLogger } from '@/composables/logger.ts'

// define props
interface Props {
    currentRate: number
    min: number
    variant?: string
    max: number
    hasSpreadFee: boolean
    selectedVehicle?: Vehicle | undefined
    deal?: ApplicationDeal
}

interface Loading {
    [key: string]: boolean
}

interface Fees {
    [key: string]: { acq: number; payment: number; dealerOrSpread: number }
}

const logger = useLogger()
const applicationStore = useApplicationStore()

const { terms, dealer } = toRefs(applicationStore)

const props = defineProps<Props>()

const emit = defineEmits<{
    close: [v: void]
    confirmed: [v: number]
}>()

//setting columns
const columns: Ref<string[]> = ref(['', 'APR'])
const isVehicleSelected: ComputedRef<boolean> = computed(() => props.selectedVehicle !== undefined)
const dealerFlatOrSpreadString: ComputedRef<string> = computed(() =>
    props.hasSpreadFee ? 'Rate Buy \nDown Fee' : 'Dealer Flat',
)
if (isVehicleSelected.value) {
    columns.value.push('Aqc. fee', dealerFlatOrSpreadString.value, 'Payment')
}

//setting rows
const rates: Ref<{ [index: string]: number | undefined | null; min?: number; max: number; custom: number | null }> =
    ref({
        max: props.max,
        min: props.min,
        custom: null,
    })
if (rates.value.min === rates.value.max) {
    delete rates.value.min
}
const selected: Ref<string> = ref('')
const validRate: Ref<boolean> = ref(false)
const cSelectedRate = computed(() => {
    return rates.value[selected.value]
})
const customMessage: Ref<string> = ref('Custom')

const validations: Ref<Record<string, (value: number) => boolean | string>> = ref({
    overMax: (value: number) => {
        if (value <= convertToPercent(rates.value.max)) {
            customMessage.value = 'Custom'
            return true
        }
        customMessage.value = 'Max Rate: ' + String(convertToPercent(rates.value.max))
        return ''
    },
    underMin: (value: number) => {
        let minRate = round(convertToPercent(rates.value.min) - MAX_DISCOUNT_PERCENTAGE, 2)
        if (!rates.value.min) {
            minRate = convertToPercent(rates.value.max)
        }

        if (value >= minRate) {
            customMessage.value = 'Custom'
            return true
        }

        customMessage.value = 'Min Rate: ' + String(minRate)
        return ''
    },
})

function setSelectedRadio(): void {
    const foundEntry = Object.entries(rates.value).find(([key, value]) => value === props.currentRate)
    if (foundEntry) {
        selected.value = foundEntry[0]
    } else {
        selected.value = 'custom'
    }
}

function setCustom(): void {
    if (props.max === props.currentRate || props.min === props.currentRate) return
    rates.value.custom = convertToPercent(props.currentRate)
}

function handleConfirm(): void {
    if (!cSelectedRate.value) return
    let confirmedValue = cSelectedRate.value
    if (selected.value == 'custom') {
        confirmedValue = convertToDecimal(confirmedValue)
    }
    emit('confirmed', confirmedValue)
}

function closeMenu(): void {
    emit('close')
}

function convertToDecimal(val: number): number {
    return round(val / 100, 4)
}

const loading: Loading = reactive({ min: false, max: false, custom: false })

// fees
const fees: Fees = reactive({
    min: { acq: 0, dealerOrSpread: 0, payment: 0 },
    max: { acq: 0, dealerOrSpread: 0, payment: 0 },
    custom: { acq: 0, dealerOrSpread: 0, payment: 0 },
})
const debounceAllFees: _.DebouncedFunc<() => void> = debounce(setAllFees, 500)

function setAllFees(): void {
    Object.entries(rates.value).forEach(async (t) => {
        if (t[1]) {
            if (t[1] > 0.9999) t[1] = convertToDecimal(t[1])
            const responseFees = await callDealStructure(t[1] ?? 0)

            updateFees(responseFees, t[0])
        }
    })
}

const debounceCustomFees: _.DebouncedFunc<() => void> = debounce(setCustomFees, 500)

async function setCustomFees(): Promise<void> {
    if (!isVehicleSelected.value) return
    loading.custom = true
    if (!validRate.value || !rates.value.custom) {
        resetCustomFeeValues()
        loading.custom = false
        return
    }
    const responseFees = await callDealStructure(convertToDecimal(rates.value.custom))
    updateFees(responseFees, 'custom')
    loading.custom = false
}

function resetCustomFeeValues(): void {
    fees.custom.acq = 0
    fees.custom.payment = 0
    fees.custom.dealerOrSpread = 0
}

function updateFees(response: DealStructure | undefined, type: string) {
    if (!response) {
        logger.error('error with updating fees in apr menu')
        return
    }
    const additionalAcq = response.fees?.additional_acq_fee ?? 0
    let spread = 0
    let totalFees: number = round(
        (response.fees?.processing ?? 0) +
            (response.fees?.acq_fee ?? 0) +
            (response.fees?.technological_convenience_fee ?? 0) +
            additionalAcq +
            (applicationStore.fees?.multiple_bk ?? 0) + (applicationStore.fees?.ch_13_bk ?? 0) + (applicationStore.fees?.open_bk ?? 0)
            ,
        2,
    )
    const highestRateTotalFee: number = round(response.fees?.highest_rate_total_fee ?? 0, 2)

    if (props.hasSpreadFee) {
        spread = response.fees?.spread_fee ?? 0
        totalFees += spread

        fees[type].acq =
            highestRateTotalFee
        fees[type].dealerOrSpread = round(totalFees - highestRateTotalFee, 2)
    } else {
        fees[type].acq = round(totalFees + additionalAcq, 2)
        fees[type].dealerOrSpread = response.fees?.dealer_participation ?? 0
    }

    fees[type].payment = response.deal.payment
    setLoader(loading, false)
}

async function callDealStructure(apr: number): Promise<DealStructure | undefined> {
    if (!props.deal?.amountFinanced || !props.deal.payment || props.deal?.amountFinanced < MIN_LOAN_AMOUNT) {
        return
    }
    if (props.deal) {
        const { currentDeal, currentVehicle } = buildVehicleDealPayload(
            props.deal,
            terms.value?.rate_capped_buy ?? 0,
            dealer.value?.state ?? '',
            props.selectedVehicle,
        )
        currentDeal.contract_rate = apr
        return await applicationStore.calculateDealStructure(currentDeal, currentVehicle)
    }
}

function setLoader(items: Object, value: boolean): void {
    Object.keys(items).forEach((k) => (loading[k] = value))
}

onMounted(() => {
    setCustom()
    setSelectedRadio()
    if (!isVehicleSelected.value) return
    setLoader(loading, true)
    debounceAllFees()
})
</script>

<style scoped>
:deep(.v-label) {
    font-size: 10px !important;
    left: -8px;
}

:deep(.v-field__input) {
    padding-left: 8px;
}
</style>
