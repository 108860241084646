<template>
    <v-toolbar color="transparent">
        <v-toolbar-items>
            <v-btn
                :to="`/dealer/${account?.dealer_id}/account/${account?.id}/inventory`"
                class="text-none !tracking-normal !text-base !font-medium !pl-0">
                <v-icon icon="$chevronLeft" size="32" class="mr-0" color="text-secondary" start></v-icon>
                Back to inventory
            </v-btn>
        </v-toolbar-items>
    </v-toolbar>
    <v-row>
        <v-col>
            <vehicle-summary
                v-if="selectedVehicle"
                :modelValue="selectedVehicle"
                @update:modelValue="onUpdateSelectedVehicle($event)"
                @calculate-deal="callDealStructure(deal, CalculationType.DealStructure)" />
            <div>
                <ad-campaign variant="horizontal" />
            </div>
        </v-col>
        <v-col>
            <v-card class="!rounded-lg !pb-2">
                <v-card-title>
                    <account-info
                        id="selected-vehicle-account-info"
                        :borrower="{ firstName: borrower?.first_name, lastName: borrower?.last_name }"
                        :coborrower="{ firstName: coborrower?.first_name, lastName: coborrower?.last_name }"
                        :account-id="String(account?.id)"
                        :application-id="String(application?.id)"
                        :show-share-button="false" />
                    <suspended-banner v-if="dealerStore.dealerIsSuspended" />
                    <funding-warning v-if="isApplicationReadOnly" />
                </v-card-title>
                <v-card-text class="pt-4">
                    <v-row>
                        <v-col cols="7">
                            <loan-structure
                                id="selected-vehicle-loan-structure"
                                :availableAdvance="availableAdvance"
                                :deal="deal"
                                :selectedVehicle="selectedVehicle"
                                :should-update-a-p-r="shouldUpdateAprByDealStructure"
                                :hasSpreadFee="dealerConfigs.hasSpreadFee"
                                @updated-apr-by-deal-structure="shouldUpdateAprByDealStructure = false"
                                @validation="onDealStructureValidation($event)"
                                @calculate-deal="callDealStructure($event.myDeal.value, $event.calculation)" />
                        </v-col>
                        <v-col cols="5">
                            <disbursement
                                v-model:dealerFlat="deal.dealerFlat"
                                v-model:totalDisbursement="deal.totalDisbursement"
                                :acquisitionFees="deal.acquisitionFees"
                                :amountFinanced="deal.amountFinanced"
                                :spreadFee="deal.spreadFee"
                                :hasSpreadFee="dealerConfigs.hasSpreadFee"
                                variant="solid" />
                        </v-col>
                    </v-row>
                    <v-row class="!mt-0">
                        <v-col cols="7">
                            <v-btn
                                class="text-none !tracking-normal !text-base !font-semibold !rounded-lg button-custom-border"
                                height="60"
                                variant="outlined"
                                color="shape-black-3rd"
                                :disabled="deal.payment == computedPayment || isApplicationReadOnly"
                                @click="resetMyDeal">
                                Reset all fields</v-btn
                            >
                        </v-col>
                        <v-col cols="5">
                            <v-btn
                                class="text-none !tracking-normal !text-base !font-semibold !rounded-lg"
                                block
                                height="60"
                                color="primary"
                                :disabled="!isSubmittable"
                                :loading="isSubmitting"
                                cy-id="submit-deal-structure"
                                @click="openConfirmMileageModal">
                                <template #loader>
                                    <v-progress-circular color="primary" indeterminate size="20" />
                                </template>
                                <p class="whitespace-break-spaces">
                                    {{ computedSubmitDealText }}
                                </p></v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="favorites.length > 0">
        <v-col class="!text-22px"> Saved Vehicles </v-col>
        <favorite-side-scroll
            :cars="favorites"
            :class="[{ 'pointer-events-none': loanStructureLoader }, 'px-4 pt-4']"
            @vehicle-changed="setFavoriteVehicle" />
    </v-row>
    <v-snackbar v-model="showSnack">
        {{ snackText }}
        <template #actions>
            <v-btn color="red" variant="text" @click="showSnack = false"> Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script lang="ts">
export default {
    name: 'SelectedVehicle',
}
</script>

<script lang="ts" setup>
import AccountInfo from '@/components/cards/AccountInfo.vue'
import VehicleSummary from '@/components/cards/VehicleSummary.vue'
import AdCampaign from '@/components/partials/AdCampaign.vue'
import Disbursement from '@/components/partials/Disbursement.vue'
import FavoriteSideScroll from '@/components/partials/FavoriteSideScroll.vue'
import LoanStructure from '@/components/partials/LoanStructure.vue'
import SuspendedBanner from '@/components/partials/banners/SuspendedBanner.vue'
import { TEditMileageResponse, useEditVehicleMileage } from '@/composables/editVehicleMileage'
import VehicleForm from '@/components/cards/VehicleForm.vue'
import {
    ACCOUNT_STATUS_CHANGED,
    INCOME_DEBT_CHANGE_EVENT,
    LOCATION_PINPOINT,
    applicationUpdatedToast,
    closeArecibo,
    connectArecibo,
    registerArecibo,
} from '@/composables/websocket'
import { MILEAGE_LEEWAY, MIN_INVOICE_VALUE } from '@/constants/amounts'
import EditVehicleType from '@/constants/editVehicleCardTypes'
import { buildFinalDealStructurePayload, buildVehicleDealPayload } from '@/helpers/payloads'
import { ApplicationDeal } from '@/models/Application/ApplicationDeal'
import { CalculationType, Deal, DealStructure as DealStructureType, VehicleDeal } from '@/models/Deal/DealStructure'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { Toast } from '@/models/toaster/toast'
import { useApplicationStore } from '@/stores/application'
import { useDealerStore } from '@/stores/dealer/dealer'
import { useInventoryStore } from '@/stores/inventory'
import { usePolicyStore } from '@/stores/policy'
import { round } from 'lodash'
import { storeToRefs } from 'pinia'
import {
    ComputedRef,
    Ref,
    computed,
    h,
    inject,
    nextTick,
    onMounted,
    reactive,
    ref,
    watch,
    onBeforeMount,
    markRaw,
} from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'

const inventoryStore = useInventoryStore()
const applicationStore = useApplicationStore()
const dealerStore = useDealerStore()
const { inventory, favorites } = storeToRefs(inventoryStore)
const {
    borrower,
    coborrower,
    account,
    loanStructureLoader,
    dealer,
    fees,
    terms,
    customer_max_payment,
    application,
    isApplicationReadOnly,
    showAPRDisclaimer: isUsuryState,
    maxAPR,
} = storeToRefs(applicationStore)
const policyStore = usePolicyStore()
const { policy } = storeToRefs(policyStore)
const modal = useGlobalModal()
const { dealerConfigs } = storeToRefs(dealerStore)
const { returnToInventory } = useEditVehicleMileage()
const route = useRoute()
const router = useRouter()
const isSubmitting: Ref<boolean> = ref(false)
const shouldUpdateAprByDealStructure: Ref<boolean> = ref(false)
const isSubmittable: ComputedRef<boolean> = computed(
    () =>
        isLoanStructureValid.value && !loanStructureLoader.value && !isApplicationReadOnly.value && !isSubmitting.value,
)

const selectedVehicle = ref<Vehicle | undefined>(
    inventory.value.find((vehicle) => vehicle.vin === route.params.vin) ??
        (route.meta.selectedVehicleFromApi as Vehicle | undefined),
)

const showSnack: Ref<boolean> = ref(false)
const snackText: Ref<string> = ref('')

let dealStructure: DealStructureType

const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}

const isLoanStructureValid = ref<boolean>(true)

const isVehicleInNorman: ComputedRef<boolean> = computed(() => {
    return selectedVehicle.value?.vin === applicationStore.application?.vehicle?.vin
})

const computedSubmitDealText: ComputedRef<string> = computed(() => {
    return isApplicationReadOnly.value ? 'Deal structure submitted' : 'Submit structure'
})

const computedAmountFinanced: ComputedRef<number> = computed(() => {
    if (isVehicleInNorman.value) {
        return terms.value?.amount_financed ?? 0
    }

    if (selectedVehicle.value?.amountFinanced) {
        return selectedVehicle.value?.amountFinanced
    }

    return 0
})

const computedMaxAmountFinanced: ComputedRef<number> = computed(() => {
    if (isVehicleInNorman.value) {
        return terms.value?.amount_financed_max ?? 0
    }
    let valuesToCompare: number[] = []
    const vehicleMax = selectedVehicle.value?.maxAmountFinanced ?? 0
    const customerMax = terms.value?.amount_financed_max_customer ?? 0
    if (vehicleMax) {
        valuesToCompare.push(vehicleMax)
    }
    if (customerMax) {
        valuesToCompare.push(customerMax)
    }
    return Math.min(...valuesToCompare)
})

const computedTerm: ComputedRef<number> = computed(() => {
    const termRule: number =
        (selectedVehicle.value?.miles ?? 0) <= policy.value.MAX_TERM_MILEAGE_CONSTRAINT
            ? policy.value.MAX_TERM_UNDER_MILEAGE_CONSTRAINT
            : policy.value.MAX_TERM_OVER_MILEAGE_CONSTRAINT
    if (isVehicleInNorman.value) {
        return terms.value?.term || termRule
    }
    return selectedVehicle.value?.maxTerm || termRule
})
const computedPayment: ComputedRef<number> = computed(() => {
    if (isVehicleInNorman.value) {
        return terms.value?.payment ?? 0
    }

    if (selectedVehicle.value?.dealStructure?.payment) {
        return selectedVehicle.value?.dealStructure?.payment
    }

    return terms.value?.payment_max ?? 0
})
const computedDealerFlat: ComputedRef<number> = computed(() => {
    return isVehicleInNorman.value
        ? fees.value?.dealer_flat ?? 0
        : selectedVehicle.value?.dealStructure?.dealerReserve ?? 0
})

const applicationTotalFees: ComputedRef<number> = computed(() => {
    return (
        (fees.value?.processing || 0) +
        (fees.value?.multiple_bk || 0) +
        ((fees.value?.acquisition_override || fees.value?.acquisition) ?? 0) +
        (fees.value?.ch_13_bk || 0) +
        (fees.value?.open_bk || 0)
    )
})

const computedAcqFees: ComputedRef<number> = computed(() => {
    return isVehicleInNorman.value ? applicationTotalFees.value : selectedVehicle.value?.dealStructure?.totalFees ?? 0
})

const computedTotalDisbursement: ComputedRef<number> = computed(() => {
    return isVehicleInNorman.value
        ? (terms.value?.amount_financed || 0) - computedAcqFees.value + computedDealerFlat.value
        : selectedVehicle.value?.dealStructure?.netDisbursement ?? 0
})

const computedApr: ComputedRef<number> = computed(() => {
    if (isVehicleInNorman.value) {
        return terms.value?.rate_contract ?? 0
    }

    if (applicationStore.maxAPR && inventoryStore.filters?.apr) {
        return Math.min(applicationStore.maxAPR, inventoryStore.filters.apr)
    }
    return applicationStore.maxAPR ?? inventoryStore.filters?.apr ?? 0
})

const deal: ApplicationDeal = reactive({
    amountFinanced: computedAmountFinanced.value,
    downPayment: terms.value?.cash_down ?? 0,
    maxAmountFinanced: computedMaxAmountFinanced.value,
    apr: computedApr.value,
    term: computedTerm.value,
    buy_rate: terms.value?.rate_capped_buy ?? 0,
    max_contract_rate: terms.value?.rate_contract ?? 0,
    maxPayment: customer_max_payment.value ?? 0,
    payment: computedPayment.value,
    acquisitionFees: computedAcqFees.value,
    dealerFlat: computedDealerFlat.value,
    totalDisbursement: computedTotalDisbursement.value,
    spreadFee: (fees.value?.spread_fee ?? 0) + (fees.value?.additional_acquisition_override ?? 0),
})

const availableAdvance: ComputedRef<number> = computed((): number => {
    if (deal.payment == deal.maxPayment) {
        return 0
    }
    return deal.maxAmountFinanced - deal.amountFinanced
})

function resetMyDeal(): void {
    deal.amountFinanced = computedAmountFinanced.value
    deal.maxAmountFinanced = computedMaxAmountFinanced.value
    deal.term = computedTerm.value
    deal.payment = computedPayment.value
    deal.apr = computedApr.value
    deal.acquisitionFees = computedAcqFees.value
    deal.dealerFlat = computedDealerFlat.value
    deal.totalDisbursement = computedTotalDisbursement.value
    deal.spreadFee = (fees.value?.spread_fee ?? 0) + (fees.value?.additional_acquisition_override ?? 0)
    callDealStructure(deal, CalculationType.DealStructure)
}

async function initialDealStructureCall() {
    if (!deal.amountFinanced) {
        await callDealStructure(deal, CalculationType.AmountFinanced)
    }
    await callDealStructure(deal, CalculationType.DealStructure)
}

function setFavoriteVehicle(car: Vehicle) {
    if (isSubmitting.value) {
        return
    }
    router.replace({
        name: 'SelectedVehicle',
        params: { dealer_id: route.params.dealer_id, account_id: route.params.account_id, vin: car.vin },
    })
    selectedVehicle.value = car
    resetMyDeal()
}

function showVehicleWithoutValueError() {
    popToastLocal({
        title: 'Vehicle Value Required',
        message: 'Something went wrong with the vehicle value. Please try again.',
        location: 'top-center',
        timer: 5000,
        persistent: true,
        autoClose: false,
        onCloseCallback: () => {
            router.push({
                name: 'Inventory',
                params: { dealer_id: account.value?.dealer_id, account_id: account.value?.id },
            })
        },
    })
}

async function callDealStructure(deal: ApplicationDeal, calculation: CalculationType): Promise<void> {
    let dealResponse: DealStructureType | undefined

    loanStructureLoader.value = true
    if (!selectedVehicle.value?.value) {
        loanStructureLoader.value = false
        showVehicleWithoutValueError()
        return
    }
    dealResponse = await getCalculation(deal, calculation)
    if (!dealResponse) {
        snackText.value = 'Failed to calculate deal'
        showSnack.value = true
    }
    setDealStructure(dealResponse)
}

async function getCalculation(deal: ApplicationDeal, calculation: CalculationType) {
    const {
        currentDeal,
        currentVehicle,
    }: {
        currentDeal: Deal
        currentVehicle: VehicleDeal
    } = getPayloadsForDealStructure(deal)
    switch (calculation) {
        case CalculationType.AmountFinanced: {
            return await applicationStore.calculateAmountFinanced(currentDeal, currentVehicle)
        }
        case CalculationType.DealStructure: {
            return await applicationStore.calculateDealStructure(currentDeal, currentVehicle)
        }
    }
}

function getPayloadsForDealStructure(deal: ApplicationDeal): {
    currentDeal: Deal
    currentVehicle: VehicleDeal
} {
    return buildVehicleDealPayload(
        deal,
        terms.value?.rate_capped_buy ?? 0,
        dealer.value?.state ?? '',
        selectedVehicle.value,
    )
}

function handleMaxAmountFinanced(apiMaxAmountFinance: number) {
    return apiMaxAmountFinance && selectedVehicle.value?.maxAmountFinanced
        ? Math.min(apiMaxAmountFinance, selectedVehicle.value?.maxAmountFinanced)
        : selectedVehicle.value?.maxAmountFinanced ?? apiMaxAmountFinance
}

function setDealStructure(dealResponse: DealStructureType | undefined): void {
    if (!dealResponse) {
        loanStructureLoader.value = false
        return
    }

    dealStructure = dealResponse
    setDealAmountFinanced(dealResponse.deal.amount_financed)
    deal.maxAmountFinanced = handleMaxAmountFinanced(dealResponse.deal.amount_financed_max)
    deal.downPayment = dealResponse.deal.down_payment ?? 0
    deal.term = dealResponse.deal.term
    if (isUsuryState.value === true && deal.apr != dealResponse.deal.contract_rate) {
        shouldUpdateAprByDealStructure.value = true
    }
    deal.apr = dealResponse.deal.contract_rate
    deal.buy_rate = dealResponse.deal.buy_rate
    deal.max_contract_rate = dealResponse.deal.max_contract_rate
    deal.payment = dealResponse.deal.payment
    deal.maxPayment = dealResponse.models?.payment_max ?? deal.maxPayment

    //here, not adding that additional acqFee
    const totalFees =
        (dealResponse.fees?.processing ?? 0) +
        (dealResponse.fees?.acq_fee ?? 0) +
        (dealResponse.fees?.technological_convenience_fee ?? 0) +
        (dealResponse.fees?.additional_acq_fee ?? 0) +
        (fees.value?.multiple_bk ?? 0) +
        (fees.value?.ch_13_bk ?? 0) +
        (fees.value?.open_bk ?? 0)


    deal.acquisitionFees = totalFees

    //#$%^$* Javascript floating points!!!!!!!
    deal.acquisitionFees = round(deal.acquisitionFees, 2)

    if (dealerConfigs.value.hasSpreadFee) {
        deal.acquisitionFees =
            (dealResponse.fees?.highest_rate_total_fee ?? 0)
        deal.spreadFee =
            totalFees + (dealResponse.fees?.spread_fee ?? 0) - (dealResponse.fees?.highest_rate_total_fee ?? 0)
    } else {
        deal.dealerFlat = dealResponse.fees?.dealer_participation ?? 0
    }

    deal.totalDisbursement = deal.amountFinanced - deal.acquisitionFees + deal.dealerFlat - deal.spreadFee
    loanStructureLoader.value = false
    return
}

function setDealAmountFinanced(amount_financed: number) {
    deal.amountFinanced = amount_financed
}

function openConfirmMileageModal(): void {
    modal.openModal({
        title: 'Confirm mileage',
        dialogProps: {
            maxWidth: '800',
        },
        component: {
            name: markRaw(VehicleForm),
            props: {
                selectedVehicle: selectedVehicle.value,
                isNew: !selectedVehicle.value?.used,
                type: EditVehicleType.ConfirmMileage,
                callback: confirmMileage,
            },
        },
    })
}

async function confirmMileage(response: TEditMileageResponse): Promise<void> {
    const { vehicle, milesDifference } = response

    if (!vehicle) {
        isSubmitting.value = false
        return popFailureToast()
    }

    if (!vehicle.value || vehicle.value < MIN_INVOICE_VALUE) {
        const deleteToastTitle = 'Confirm failed, unable to confirm mileage'
        const message =
            'With this update, the vehicle’s value does not meet Arivo’s required minimum value and has been removed from your inventory. If you made a mistake, you can always add it back manually.'
        returnToInventory(deleteToastTitle, message)
        return
    }

    selectedVehicle.value = {
        ...vehicle,
    }

    if (Math.abs(milesDifference) >= MILEAGE_LEEWAY) {
        await nextTick()
        callDealStructure(deal, CalculationType.DealStructure)
        popToastLocal({
            message:
                'Your updated Mileage may have affected your loan structure, please confirm your structure and then submit.',
            timer: 5000,
            location: 'top-center',
        })
    } else {
        await submitDealStructure()
    }
}

async function submitDealStructure(): Promise<void> {
    isSubmitting.value = true
    try {
        const finalDealStructure: ReturnType<typeof buildFinalDealStructurePayload> = buildFinalDealStructurePayload(
            inventoryStore.id,
            dealStructure,
            application.value,
            selectedVehicle.value,
        )
        await applicationStore.submitFinalDealStructure(finalDealStructure)
        await applicationStore.getAccount(application.value?.account_id!)
        popSuccessToast()
    } catch (error: any) {
        if (error?.message?.includes('vehicle not found in inventory')) {
            popToastLocal({
                title: 'Unable To Submit Structure',
                message: 'This vehicle is no longer in inventory.',
                location: 'top-center',
                autoClose: false,
                onCloseCallback: () => {
                    router.push({
                        name: 'Inventory',
                        params: { dealer_id: account.value?.dealer_id, account_id: account.value?.id },
                    })
                },
            })
            return
        }
        popFailureToast()
    }
    isSubmitting.value = false
}

function onDealStructureValidation(isValid: boolean) {
    isLoanStructureValid.value = isValid
}

async function onUpdateSelectedVehicle(vehicle: Vehicle | undefined) {
    if (!vehicle) {
        return
    }
    selectedVehicle.value = { ...selectedVehicle.value, ...vehicle }
    await nextTick()
}

function popSuccessToast(): void {
    popToastLocal({
        title: 'Success, Structure Received!',
        message: 'Your approval has been updated with this vehicle and loan information.',
        timer: 50000,
        location: 'top-center',
    })
}

function popFailureToast(): void {
    popToastLocal({
        title: 'Submission Failed, Unable To Submit Structure',
        message: "We ran into a problem while trying to update your application's vehicle and loan information.",
        timer: 5000,
        location: 'top-center',
    })
}

function FundingWarning() {
    return [
        h('div', { class: 'p-4 bg-white rounded-lg text-[#D3214B]  text-base font-medium' }, [
            'Your application is in for funding. To update your structure, please call the funding department: 801-783-2240.',
        ]),
    ]
}

function updatedAPR(alert: boolean) {
    shouldUpdateAprByDealStructure.value = false
    if (alert) {
        popToastLocal({
            title: 'Updated APR',
            message: 'We automatically adjusted the APR based on local law.',
            timer: 5000,
            location: 'top-center',
        })
    }
}
watch(
    () => deal.apr,
    () => {
        inventoryStore.getFavorites(account.value?.id, deal.apr)
    },
)
onBeforeMount(() => {
    if (!selectedVehicle.value)
        router.push({
            name: 'Inventory',
            params: { dealer_id: dealer.value?.id, account_id: account.value?.id },
        })
})
onMounted(async () => {
    await inventoryStore.getFavorites(account.value?.id, maxAPR.value)
    await initialDealStructureCall()

    await connectArecibo()
    registerArecibo(account.value?.id.toString() || '', LOCATION_PINPOINT, (payload) => {
        if (
            payload?.data?.eventType === INCOME_DEBT_CHANGE_EVENT ||
            payload?.data?.eventType === ACCOUNT_STATUS_CHANGED
        ) {
            popToastLocal(applicationUpdatedToast)
        }
    })
})

onBeforeRouteLeave(() => {
    closeArecibo(account.value?.id.toString() || '')
})
</script>
