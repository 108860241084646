<template>
    <div class="px-20 pt-6">
        <p
            v-if="(type == EditVehicleType.AddNewVehicle || type == EditVehicleType.AddUsedVehicle) && newVehicle"
            class="text-xs pb-4">
            If this vehicle is being sold as used, please call in the structure to your Underwriting team. A book value
            will be required and the vehicle will follow our used Loan to Value Policy. If a Book Value cannot be
            produced from our sources on the vehicle, we will use the Factory Invoice Value and Finance a maximum Loan
            to Value (LTV of 90% of that Factory Invoice Value).
        </p>
        <p
            v-show="
                (type == EditVehicleType.AddUsedVehicle || type === EditVehicleType.AddNewVehicle) &&
                !selectedVehicle?.make &&
                !selectedVehicle?.year &&
                !selectedVehicle?.model
            "
            class="text-center text-red-500 text-md">
            We were unable to find this VIN, please check your VIN or manually enter this vehicle’s information
        </p>
        <p v-if="type == EditVehicleType.ConfirmMileage" class="text-base">
            Please Confirm that the mileage reported in your inventory is accurate to ensure your vehicle value and loan
            structure will be correct.
        </p>
    </div>
    <v-responsive>
        <v-container>
            <v-form ref="form" v-model="formValid" class="pt-6 pb-12">
                <v-row justify="space-evenly">
                    <v-col cols="5">
                        <v-text-field
                            v-model="state.vin"
                            data-testid="vehicle-form-vin"
                            clearable
                            maxLength="17"
                            variant="filled"
                            :disabled="
                                type === EditVehicleType.EditInvoice ||
                                type === EditVehicleType.EditMileage ||
                                type === EditVehicleType.ConfirmMileage
                            "
                            :rules="[validators.validVin, validators.noSpecialChars]"
                            label="VIN"
                            @keydown.space.prevent
                            @update:modelValue="onVinModelUpdate($event)"></v-text-field>
                        <v-select
                            v-if="type != EditVehicleType.ConfirmMileage"
                            v-model="state.year"
                            data-testid="vehicle-form-year"
                            :disabled="type === EditVehicleType.EditInvoice || type === EditVehicleType.EditMileage"
                            clearable
                            variant="filled"
                            placeholder="Select a Year"
                            :error-messages="cYearValidation"
                            :items="cYearOptions"
                            label="Model year"
                            :rules="[validators.required]"
                            @update:model-value="validate"></v-select>
                        <v-text-field
                            v-model="state.make"
                            clearable
                            :disabled="
                                type === EditVehicleType.EditInvoice ||
                                type === EditVehicleType.EditMileage ||
                                type === EditVehicleType.ConfirmMileage
                            "
                            variant="filled"
                            data-testid="vehicle-form-make"
                            label="Make"
                            :rules="[validators.required]"></v-text-field>

                        <v-text-field
                            v-model="state.model"
                            variant="filled"
                            :disabled="
                                type === EditVehicleType.EditInvoice ||
                                type === EditVehicleType.EditMileage ||
                                type === EditVehicleType.ConfirmMileage
                            "
                            clearable
                            data-testid="vehicle-form-model"
                            :rules="[validators.required]"
                            label="Model"></v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <currency-input
                            v-model="state.mileage"
                            :vuetify-props="{
                                label: 'Mileage',
                                clearable: true,
                                disabled: type === EditVehicleType.EditInvoice,
                                variant: 'filled',
                                validateOn: 'submit',
                                errorMessages: cMileageOverMessage,
                                rules: [validators.required],
                            }"
                            @update:model-value="validate"
                            data-testid="vehicle-form-mileage"
                            :options="{
                                currency: 'USD',
                                currencyDisplay: CurrencyDisplay.hidden,
                                precision: 0,
                                valueRange: { min: 0 },
                            }" />

                        <currency-input
                            v-if="type != EditVehicleType.EditInvoice"
                            v-model="state.confirmMileage"
                            data-testid="vehicle-form-confirm-mileage"
                            @update:model-value="validate"
                            :vuetify-props="{
                                label: 'Confirm mileage',
                                clearable: true,
                                variant: 'filled',
                                errorMessages: cConfirmedMileageMessage,
                                validateOn: 'submit',
                                rules: [validators.required],
                            }"
                            :options="{
                                currency: 'USD',
                                currencyDisplay: CurrencyDisplay.hidden,
                                precision: 0,
                                valueRange: { min: 0 },
                            }" />
                        <v-select
                            v-if="type == EditVehicleType.AddNewVehicle || type == EditVehicleType.AddUsedVehicle"
                            v-model="state.duration"
                            data-testid="vehicle-form-duration"
                            variant="filled"
                            clearable
                            :items="cDurationOptions"
                            label="Inventory Duration"
                            :rules="[validators.required]"></v-select>
                        <currency-input
                            v-if="newVehicle"
                            v-model="state.invoice"
                            data-testid="vehicle-form-invoice"
                            :disabled="type === EditVehicleType.ConfirmMileage || type === EditVehicleType.EditMileage"
                            @update:model-value="validate"
                            :vuetify-props="{
                                label: 'Factory invoice',
                                variant: 'filled',
                                clearable: true,
                                validateOn: 'submit',
                                rules: [validators.required, invoiceValidation],
                            }"
                            :options="{
                                currency: 'USD',
                                currencyDisplay: CurrencyDisplay.hidden,
                                precision: 0,
                                valueRange: { min: 0 },
                            }" />
                    </v-col>
                </v-row>
            </v-form>

            <v-row class="bg-vuetify-background">
                <v-col>
                    <v-btn
                        variant="flat"
                        data-testid="vehicle-form-btn"
                        size="x-large"
                        :disabled="!formValid"
                        width="150"
                        :text="
                            type === EditVehicleType.AddUsedVehicle ||
                            type === EditVehicleType.AddNewVehicle ||
                            type === EditVehicleType.ConfirmMileage
                                ? 'Continue'
                                : 'Save'
                        "
                        append-icon="$arrowRight"
                        class="!normal-case !tracking-normal !text-shades-black float-right"
                        :loading="cLoading"
                        color="primary"
                        @click="handleBtnAction">
                        <template #loader>
                            <v-progress-circular indeterminate size="24"></v-progress-circular>
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-responsive>
</template>

<script setup lang="ts">
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import EditVehicleType from '@/constants/editVehicleCardTypes.ts'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { CurrencyDisplay } from 'vue-currency-input'
import { computed, ComputedRef, onMounted, reactive, Ref, ref, watch, watchEffect } from 'vue'
import { isNew } from '@/helpers/vehicles.ts'
import { MAX_MILEAGE_FOR_CURRENT_YEAR_CAR, MIN_INVOICE_VALUE, MIN_LOAN_AMOUNT } from '@/constants/amounts.ts'
import { daysTillExpireDateTime, onlyLettersAndNumbers } from '@/helpers/sanitizers.ts'
import { useApplicationStore } from '@/stores/application.ts'
import { storeToRefs } from 'pinia'
import { usePolicyStore } from '@/stores/policy.ts'
import dayjs from 'dayjs'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import { useAddVehicle } from '@/composables/addVehicle.ts'
import { isUndefined } from 'lodash'
import { useEditVehicleInvoice } from '@/composables/editVehicleInvoice.ts'
import { useEditVehicleMileage } from '@/composables/editVehicleMileage.ts'
import { validators } from '@/helpers/validators.ts'

interface Props {
    type: EditVehicleType
    isNew?: boolean
    selectedVehicle?: Vehicle
    callback: Function
}

export type TVehicleState = {
    vin: string
    make: string
    model: string
    duration: string
    year: number | null
    mileage: number | null
    confirmMileage: number | null
    invoice: number | null
    isUsed: boolean
}

const props = defineProps<Props>()

const applicationStore = useApplicationStore()
const modal = useGlobalModal()
const { policy } = storeToRefs(usePolicyStore())
const { addVehicle, loading: addingVehicle } = useAddVehicle()
const { updateVehicleValue, loading: editingInvoice } = useEditVehicleInvoice()
const { editMileage, loading: editingMileage } = useEditVehicleMileage()
const state = reactive<TVehicleState>({
    vin: '',
    make: '',
    model: '',
    duration: '',
    year: null,
    mileage: null,
    confirmMileage: null,
    invoice: null,
    isUsed: false,
})

const form: Ref<HTMLFormElement | null> = ref(null)
const formValid: Ref<boolean> = ref(false)
const rules = {
    overMinLoanAmount: () => {
        return Number(state.invoice) >= MIN_LOAN_AMOUNT || `Must be higher than ${MIN_LOAN_AMOUNT}`
    },
}

const cYearOptions: ComputedRef<Array<number>> = computed(() => {
    const years: Array<number> = []
    const currentYear: number = new Date().getFullYear()
    const min: number = applicationStore.minYearAllowedToAddVehicle
    const max: number = currentYear + 1
    for (let year: number = max; year >= min; year--) {
        years.push(year)
    }
    return years
})

const newVehicle: ComputedRef<boolean> = computed(() => {
    const nullVehicle = !state.year && !state.mileage
    if (props.isNew) {
        return true
    }
    if (props.type === EditVehicleType.AddNewVehicle && nullVehicle) {
        return true
    }
    return isNew(Number(state.year), Number(state.mileage), dayjs().format('YYYY-MM-DD'))
})

const cLoading: ComputedRef<boolean> = computed(
    () => editingInvoice.value || addingVehicle.value || editingMileage.value,
)

const cDurationOptions: ComputedRef<Array<{ title: string; value: string }>> = computed(() => {
    const allowedDays = [1, 5, 15, 30] as const
    const setDefaultText = (day: number): string => (day < 5 ? '(default)' : '')
    return allowedDays.map((day) => {
        return {
            title: `${day} days ${setDefaultText(day)}`,
            value: daysTillExpireDateTime(day),
        }
    })
})

const cMileageOverMessage: ComputedRef<string> = computed(() => {
    const currentYear = dayjs().year()

    if (Number(state.mileage) > policy.value.MAX_MILEAGE_ALLOWED) {
        return `Please enter a mileage that is ${Intl.NumberFormat().format(policy.value.MAX_MILEAGE_ALLOWED)} or less.`
    }

    return ''
})
const cConfirmedMileageMessage: ComputedRef<string> = computed(() =>
    state.confirmMileage === state.mileage ? '' : 'Mileage and confirm mileage must match',
)
const cYearValidation: ComputedRef<string> = computed(() => {
    return !state.year || cYearOptions.value.includes(Number(state.year)) ? '' : 'Year is out of range'
})
function invoiceValidation(): boolean | string {
    if (props.type !== EditVehicleType.EditInvoice) {
        return rules.overMinLoanAmount()
    }
    return Number(state.invoice) >= MIN_INVOICE_VALUE || `Must be higher than ${MIN_INVOICE_VALUE}`
}

async function validate(): Promise<void> {
    await form.value?.validate()
}

function onVinModelUpdate(inputVin: string): void {
    if (!inputVin) return
    state.vin = inputVin
    state.vin = onlyLettersAndNumbers(inputVin).toUpperCase()
}

async function handleBtnAction(): Promise<void> {
    state.isUsed = !newVehicle.value
    switch (props.type) {
        case EditVehicleType.AddNewVehicle:
        case EditVehicleType.AddUsedVehicle:
            await addVehicle(state)
            break
        case EditVehicleType.EditInvoice:
            props.callback(await updateVehicleValue(state, props.selectedVehicle))
            break
        default:
            props.callback(await editMileage(state, props.selectedVehicle))
            break
    }
    modal.closeModal()
}

watch(newVehicle, (val) => {
    if (props.type === EditVehicleType.AddUsedVehicle || props.type === EditVehicleType.AddNewVehicle) {
        modal.state.title = `Add ${val ? 'new' : 'used'} vehicle`
    }
})

onMounted(() => {
    if (props.selectedVehicle) {
        state.vin = props.selectedVehicle.vin
        state.year = props.selectedVehicle?.year || null
        state.make = props.selectedVehicle?.make || ''
        state.model = props.selectedVehicle?.model || ''
        state.duration = props.selectedVehicle.expiresAt || cDurationOptions.value[0].value
        state.mileage = isUndefined(props.selectedVehicle?.miles) ? null : props.selectedVehicle.miles || 0
        state.invoice = props.selectedVehicle?.value || null
        state.isUsed = props.selectedVehicle.used
    }
    state.duration = cDurationOptions.value[0].value
})
</script>

<style scoped lang="scss">
:deep(.v-theme--light) {
    --v-disabled-opacity: 0.5;
}
</style>
