<template>
    <v-combobox
        ref="searchRef"
        :model-value="inventoryStore.filters.search"
        v-model:search.trim="search"
        cy-id="inventory-search-input"
        :items="foundResults.inventory.slice(0, 5)"
        hide-details
        :hide-no-data="false"
        :loading="loading"
        append-inner-icon="$magnify"
        variant="underlined"
        item-title="make"
        :menu="testing"
        :menu-props="{ maxHeight: '600', attach: testing, openOnHover: true }"
        chips
        closable-chips
        no-filter
        menu-icon=""
        placeholder="Search by VIN, Stock number, Make, Model, or Year">
        <template #chip="{ props }">
            <v-chip v-bind="props" class="!border-gray-400" border @click:close="handleChipClear">
                <template #close>
                    <v-icon
                        data-testid="inventory_search_clear_chip_icon"
                        icon="$closeCircle"
                        size="16"
                        color="grey-darken-3" />
                </template>
            </v-chip>
        </template>
        <template #item="{ props: menu, item }">
            <v-hover v-slot="{ isHovering, props: hover }">
                <v-list-item
                    v-bind="mergeProps(menu, hover)"
                    :key="item.raw.vin"
                    class="!border-b !border-black !p-0"
                    :class="{ 'bg-indigo-lighten-5 elevation-2': isHovering }"
                    @click="sendToSelectedVehicle(item.raw.vin)">
                    <template #title></template>
                    <div class="grid grid-cols-12 gap-4 !text-sm pl-4 pr-2">
                        <div :class="`${highlightText(item.raw.make)} col-span-3`">{{ item.raw.make }}</div>
                        <div :class="`${highlightText(item.raw.model)} col-span-3`">{{ item.raw.model }}</div>
                        <div :class="`${highlightText(item.raw.year?.toString())} col-span-3`">
                            {{ item.raw.year?.toString() }}
                        </div>
                        <div :class="`col-span-3`">{{ money(item.raw.price) }}</div>
                        <div :class="`${highlightText(item.raw.stockNo)} col-span-3`">{{ item.raw.stockNo }}</div>
                        <div :class="`${highlightText(item.raw.vin)} col-span-6`">{{ item.raw.vin }}</div>
                        <div :class="`col-span-3`">{{ money(item.raw.dealStructure?.payment) }} / mo</div>
                    </div>
                    <template #append>
                        <v-img
                            :src="item.raw?.photoURL"
                            :lazy-src="default_car_image"
                            width="96"
                            @error="item.raw.photoURL = default_car_image" />
                    </template>
                </v-list-item>
            </v-hover>
        </template>
        <template #prepend-item>
            <div
                v-if="foundResults.inventory.length"
                class="text-sm border-t border-b py-2 px-4 border-black text-blue-grey-darken-2">
                <p class="font-semibold">
                    Showing results for
                    <span class="font-italic font-normal">"{{ search }}"</span>
                </p>
            </div>
            <div v-else-if="showSearchResults && !foundResults.inventory.length">
                <div class="text-sm border-t border-b py-2 px-4 border-black text-blue-grey-darken-2">
                    <p>No results found for {{ search }}</p>
                </div>
            </div>
        </template>
        <template #append-item v-if="foundResults.inventory.length > 5">
            <v-container fluid class="!pb-2">
                <v-row justify="end">
                    <v-btn
                        cy-id="see-all-search-results-btn"
                        variant="text"
                        size="small"
                        class="!tracking-normal text-none !font-bold !text-sm"
                        :text="`See all ${foundResults.pagination.totalRecords} results`"
                        @click.stop="displayInInventory"></v-btn>
                </v-row>
            </v-container>
        </template>
        <template #no-data>
            <div class="font-semibold !text-sm px-4 py-2">
                <p>Search tips</p>
                <ul class="pl-4 !list-disc space-y-2 pt-2 font-normal">
                    <li class="pl-2">Check to make sure the VIN/Stock number is valid</li>
                    <li class="pl-2">Check spelling</li>
                </ul>
            </div>
        </template>
    </v-combobox>
</template>
<script setup lang="ts">
import { money } from '@/helpers/formatters'
import { mergeProps, reactive, ref, Ref, watch } from 'vue'

import {
    IFilterQueryParams,
    IFilters,
    InventoryFiltersResponse,
    inventorySortByMap,
} from '@/models/Dealer/InventoryFilters'
import { useInventoryStore } from '@/stores/inventory'
import { storeToRefs } from 'pinia'
import { useApplicationStore } from '@/stores/application'
import { debounce } from 'lodash'
import default_car_image from '@/assets/default_car_image.png'
import { useRouter } from 'vue-router'
import { useDealerStore } from '@/stores/dealer/dealer'

withDefaults(defineProps<{ testing?: boolean }>(), {
    testing: false,
})

const inventoryStore = useInventoryStore()
const { dealer } = storeToRefs(useDealerStore())
const { account } = storeToRefs(useApplicationStore())
const searchFilters: Ref<IFilters> = ref({} as IFilters)
const filters = reactive<IFilterQueryParams>({
    page: 1,
    pageSize: 20,
    pageCount: 1,
    apr: inventoryStore.filters.apr,
    sort_by: '-relevance',
    used: inventoryStore.filters.used,
})
const foundResults: Ref<InventoryFiltersResponse> = ref({
    inventory: [],
    pagination: {
        page: 1,
        pageCount: 1,
        pageSize: 20,
        totalNew: 0,
        totalUsed: 0,
        totalRecords: 0,
    },
})
const router = useRouter()

const searchRef: Ref<HTMLInputElement | null> = ref(null)
const search: Ref<string> = ref('')
const showSearchResults: Ref<boolean> = ref(false)
const loading: Ref<boolean> = ref(false)

function highlightText(text: string = ''): string {
    if (!search.value) return text ?? ''
    const pattern = new RegExp('\\b(' + search.value.split(/\s+/).map(escapeRegExp).join('|') + ')\\b', 'gi')

    return text.match(pattern) ? 'font-semibold' : ''
}

function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

async function handleChipClear(): Promise<void> {
    if (inventoryStore.filters.search) {
        inventoryStore.filters.sort_by = inventorySortByMap.estimatedGross
        inventoryStore.filters.search = undefined
        await inventoryStore.setInventoryFilters(account.value?.id)
    }
    return
}

function sendToSelectedVehicle(vin?: string): void {
    if (!vin) return
    router.push({
        name: 'SelectedVehicle',
        params: { dealer_id: dealer.value.id, account_id: account.value?.id, vin: vin },
    })
}

function sanitizeSearch() {
    if (!search.value) return
    search.value = search.value.replace(/\\|\[|\(|\+|\*|\-/g, '')
}

const update: () => void = debounce(() => {
    getSearchInventory()
}, 1000)

watch(search, () => {
    if (!search.value || search.value === '') {
        foundResults.value.inventory = []
        showSearchResults.value = false
        return
    }
    sanitizeSearch()
    update()
})

function displayInInventory(): void {
    inventoryStore.inventoryFilters = searchFilters.value
    inventoryStore.filters = { ...inventoryStore.filters, ...filters }
    inventoryStore.inventory = foundResults.value.inventory
    searchRef.value?.blur()
}

async function getSearchInventory(): Promise<void> {
    if (!search.value) return
    filters.search = search.value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
    try {
        loading.value = true
        const filterResponse = (await inventoryStore.getNewFilters(filters, account.value?.id)) as IFilters
        if (filterResponse) {
            searchFilters.value = { ...filterResponse }
        }
        const inventoryResponse = (await inventoryStore.getInventory(
            filters,
            account.value?.id,
        )) as InventoryFiltersResponse
        if (inventoryResponse.inventory) {
            foundResults.value = inventoryResponse
            filters.page = inventoryResponse.pagination.page
            filters.pageCount = inventoryResponse.pagination.pageCount
        } else {
            foundResults.value.inventory = []
        }
    } catch (error) {
        return
    } finally {
        loading.value = false
        showSearchResults.value = true
    }
}

defineExpose({ displayInInventory, handleChipClear })
</script>
