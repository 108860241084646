<template>
    <v-card class="mb-2 !p-2 pb-4 !rounded-lg" min-width="450" height="560">
        <v-card-title class="!text-2xl">
            {{ modelValue?.year }} {{ modelValue?.make }} {{ modelValue?.model }} {{ modelValue?.trim }}
        </v-card-title>
        <v-card-item>
            <v-img width="100%" height="337" :lazy-src="default_car_image" :src="modelValue?.photoURL">
                <template v-slot:error>
                    <v-img width="100%" height="337" :src="default_car_image"></v-img>
                </template>
            </v-img>
        </v-card-item>
        <v-card-item class="relative">
            <v-row justify="space-between" class="absolute w-full bottom-0 h-full break-words">
                <v-col>
                    <p data-testid="vehicle_summary_list_price" class="!font-bold !text-base">
                        {{ money(computedListPrice) }}
                    </p>
                    <p v-if="!modelValue?.used" class="!font-medium !text-sm whitespace-nowrap">
                        Factory invoice | {{ money(modelValue?.value?.toFixed(2)) ?? '' }}
                        <v-btn
                            icon
                            density="compact"
                            data-testid="selected-vehicle-factory-invoice-icon"
                            :disabled="applicationStore.isApplicationReadOnly"
                            @click="openGlobalModal(true)">
                            <v-icon icon="$pencilOutline" size="18" />
                        </v-btn>
                    </p>
                    <p class="!font-medium !text-sm">
                        Mileage* | {{ formatMileage(modelValue?.miles ?? 0) }}
                        <v-btn
                            icon
                            data-testid="edit-vehicle-mileage-icon"
                            density="compact"
                            :disabled="applicationStore.isApplicationReadOnly"
                            @click="openGlobalModal()">
                            <v-icon icon="$pencilOutline" size="18" />
                        </v-btn>
                    </p>
                    <p class="text-[10px]">* Exact mileage is required for accurate loan structure maximums</p>
                </v-col>
                <v-col class="text-right !text-sm self-center space-y-1">
                    <p>Stock # | {{ modelValue?.stockNo }}</p>
                    <p>VIN | {{ modelValue?.vin }}</p>
                </v-col>
            </v-row>
        </v-card-item>
    </v-card>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, inject, markRaw, nextTick, ref, Ref } from 'vue'
import default_car_image from '@/assets/default_car_image.png'
import { useApplicationStore } from '@/stores/application'
import { MIN_INVOICE_VALUE } from '@/constants/amounts'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import EditVehicleType from '@/constants/editVehicleCardTypes'
import { Toast } from '@/models/toaster/toast'
import { money } from '@/helpers/formatters.ts'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import VehicleForm from '@/components/cards/VehicleForm.vue'
import { TEditMileageResponse, useEditVehicleMileage } from '@/composables/editVehicleMileage.ts'

const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}
const applicationStore = useApplicationStore()
const { returnToInventory } = useEditVehicleMileage()
// define props
interface Props {
    modelValue: Vehicle
}

const props = defineProps<Props>()

// component's events
interface EventsEmitted {
    (event: 'update:modelValue', vehicle: Vehicle | undefined): void

    (e: 'calculate-deal'): void
}

const emit: EventsEmitted = defineEmits<EventsEmitted>()

const modal = useGlobalModal()

const updatedSelectedVehicle: Ref<Vehicle | undefined> = ref(props.modelValue)

// vehicle details
const computedListPrice: ComputedRef<string | number> = computed(() => {
    if (props.modelValue?.price && props.modelValue.price.toString().length > 1) {
        return props.modelValue.price.toFixed(2)
    } else if (!props.modelValue?.used && props.modelValue?.msrp && props.modelValue.msrp.toString().length > 1) {
        return props.modelValue.msrp.toFixed(2)
    } else {
        return '0'
    }
})

function setInvoiceAndCalculate(value?: number): void {
    if (value) {
        props.modelValue.value = value
        calculateDeal()
    }
}

async function setMileageAndCalculate(response: TEditMileageResponse): Promise<void> {
    const { vehicle } = response
    if (!vehicle) {
        popToastLocal({
            title: 'Submission Failed, Unable To Submit Mileage Update',
            message: "We ran into a problem while trying to update your application's vehicle and loan information.",
            timer: 5000,
            location: 'top-center',
        })
        return
    }

    if (!vehicle.value || vehicle.value < MIN_INVOICE_VALUE) {
        const deleteToastTitle = 'Submission Failed, Unable To Submit Mileage Update'
        const message =
            'With this update, the vehicle’s value does not meet Arivo’s required minimum value and has been removed from your inventory. If you made a mistake, you can always add it back manually.'
        returnToInventory(deleteToastTitle, message)
    } else {
        updateSelectedVehicle(vehicle)
    }
    await nextTick()
    calculateDeal()
}

function openGlobalModal(editInvoice = false): void {
    const title = editInvoice ? 'invoice' : 'mileage'
    const type = editInvoice ? EditVehicleType.EditInvoice : EditVehicleType.EditMileage
    const callback = editInvoice ? setInvoiceAndCalculate : setMileageAndCalculate

    modal.openModal({
        title: `Edit ${title}`,
        dialogProps: { maxWidth: '800' },
        component: {
            name: markRaw(VehicleForm),
            props: {
                selectedVehicle: props.modelValue,
                isNew: !props.modelValue.used,
                type,
                callback,
            },
        },
    })
}

// internal function component
function calculateDeal(): void {
    emit('calculate-deal')
}

function updateSelectedVehicle(vehicle: Vehicle | null): void {
    if (updatedSelectedVehicle.value && vehicle) {
        updatedSelectedVehicle.value.value = vehicle.value
        updatedSelectedVehicle.value.miles = vehicle.miles
        updatedSelectedVehicle.value.maxTerm = vehicle.maxTerm
        updatedSelectedVehicle.value.used = vehicle.used
    }

    emit('update:modelValue', updatedSelectedVehicle.value)
}

function formatMileage(mileage: number): string {
    return mileage.toLocaleString()
}
</script>
