<template>
    <v-container>
        <v-row>
            <v-col>
                <span v-text="message" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    variant="outlined"
                    size="large"
                    data-testid="delete-vehicle-cancel-btn"
                    width="100%"
                    class="text-none !tracking-normal flex-1 grow"
                    color="primary"
                    text="Cancel"
                    @click="modal.closeModal"></v-btn>
            </v-col>
            <v-col>
                <v-btn
                    color="primary"
                    width="100%"
                    data-testid="delete-vehicle-confirm-btn"
                    class="text-none !tracking-normal flex-1 grow"
                    size="large"
                    text="Confirm"
                    :loading="loading"
                    @click="deleteVehicle">
                    <template #loader>
                        <v-progress-circular indeterminate size="20" color="white" />
                    </template>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue'
import { useInventoryStore } from '@/stores/inventory.ts'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import { useApplicationStore } from '@/stores/application.ts'

const props = defineProps<{ vin?: string }>()

const inventoryStore = useInventoryStore()
const applicationStore = useApplicationStore()
const modal = useGlobalModal()
const loading: Ref<boolean> = ref(false)
async function deleteVehicle(): Promise<void> {
    loading.value = true
    await inventoryStore.deleteInventoryVehicle(props.vin ?? '', applicationStore.account?.id)
    loading.value = false
    modal.closeModal()
}

const message: Ref<string> = ref(
    'Are you sure you want to remove this vehicle? Removing this vehicle will delete it from your Pinpoint inventory and if you want to add it back to your inventory you will have to do it again manually',
)
</script>
