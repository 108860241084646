<template>
    <v-container>
        <v-form v-model="formValid" class="py-8" @submit.prevent>
            <v-row justify="center">
                <v-col cols="12" sm="8">
                    <v-text-field
                        class="add-vin-modal-vin"
                        data-testid="vin-modal-vin"
                        v-model="vin"
                        clearable
                        variant="filled"
                        bg-color="bg-surface-200"
                        maxLength="17"
                        :rules="[validators.validVin, validators.noSpecialChars, validators.required]"
                        label="VIN"
                        @update:modelValue="onVinModelUpdate($event)"></v-text-field>
                </v-col>
            </v-row>
        </v-form>

        <v-row class="pt-2 bg-vuetify-background">
            <v-spacer />
            <v-col cols="auto" class="mr-2.5">
                <v-btn
                    :loading="loading"
                    :disabled="!formValid"
                    data-testid="add-vin-modal-action-btn"
                    variant="flat"
                    size="x-large"
                    width="150"
                    text="Search by vin"
                    class="!normal-case !tracking-normal !text-shades-black"
                    color="primary"
                    @click="lookUpVin">
                    <template #loader>
                        <v-progress-circular indeterminate size="24"> </v-progress-circular>
                    </template>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { markRaw, ref, Ref } from 'vue'
import { useAddVehicle } from '@/composables/addVehicle.ts'
import { useRouter } from 'vue-router'
import { useApplicationStore } from '@/stores/application.ts'
import { storeToRefs } from 'pinia'
import { useDealerStore } from '@/stores/dealer/dealer.ts'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import VehicleForm from '@/components/cards/VehicleForm.vue'
import EditVehicleType from '@/constants/editVehicleCardTypes.ts'
import { onlyLettersAndNumbers } from '@/helpers/sanitizers.ts'
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import { validators } from '@/helpers/validators.ts'

const router = useRouter()

//composables
const applicationStore = useApplicationStore()
const modal = useGlobalModal()
const { dealer } = storeToRefs(useDealerStore())
const { loading, getVehicleFromInventory } = useAddVehicle()

const vin: Ref<string> = ref('')
const formValid: Ref<boolean> = ref(false)

//functions
async function lookUpVin(): Promise<void> {
    const vehicle = await getVehicleFromInventory(vin.value)
    if (vehicle && vehicle.value) {
        router.push({
            name: 'SelectedVehicle',
            params: {
                dealer_id: dealer.value.id,
                account_id: applicationStore.account?.id,
                vin: vehicle.vin,
            },
        })
        modal.closeModal()
        return
    }
    openVehicleFormModal(vehicle)
}
function onVinModelUpdate(inputVin: string): void {
    if (!inputVin) return
    vin.value = inputVin
    vin.value = onlyLettersAndNumbers(inputVin).toUpperCase()
}

function openVehicleFormModal(vehicle?: Vehicle) {
    const newVehicle = modal.state.title?.includes('new')

    if (!vehicle) {
        vehicle = {
            vin: vin.value,
            favorited: false,
            used: !newVehicle,
        }
    }

    modal.openModal({
        title: modal.state.title,
        dialogProps: {
            maxWidth: '800',
            persistent: true,
        },
        component: {
            name: markRaw(VehicleForm),
            props: {
                selectedVehicle: vehicle,
                type: EditVehicleType[newVehicle ? 'AddNewVehicle' : 'AddUsedVehicle'],
            },
        },
    })
}
</script>
