<template>
    <v-app-bar class="!border-b-2 !border-vuetify-primary px-2">
        <template #prepend>
            <v-img width="128" alt="pinpointLogo" :src="logo" />
        </template>
        <v-tabs v-model="selectedPage" slider-color="primary" class="ml-4">
            <v-tab
                v-for="page in pages"
                :key="page"
                :value="page"
                data-testid="link-buttons"
                class="text-none !tracking-normal my-2 font-inter !font-semibold !text-base"
                :to="`/dealer/${computedDealerId}/${page.toLowerCase()}`"
                >{{ page }}
            </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
            v-if="showSearchBar"
            density="compact"
            hide-details
            max-width="100"
            rounded
            :modelValue="searchTerm"
            placeholder="Search"
            @update:modelValue="(term: string) => (searchTerm = term)">
            <template #prepend-inner>
                <v-icon icon="$magnify" size="16" class="mr-0" start></v-icon>
            </template>
        </v-text-field>
        <v-btn
            v-if="phoneNumber"
            variant="text"
            data-testid="nav-phone-number"
            color="primary"
            class="text-none !tracking-normal mx-2"
            @click="handlePhoneClick">
            <v-icon icon="$phoneOutline" start />
            {{ phoneNumber }}
            <v-tooltip contained v-model="phoneNumberCopied" location="top" text="Copied!"></v-tooltip>
        </v-btn>
        <template #append>
            <v-chip size="x-large" color="info">
                <v-avatar color="white" start>
                    <v-icon icon="$trainCar" color="indigo-darken-4"></v-icon>
                </v-avatar>
                <template #append>
                    <div>
                        <p class="text-xs font-medium">{{ dealerStore.dealer?.name }}</p>
                        <p class="text-xs font-medium">{{ userName }}</p>
                    </div>
                </template>
            </v-chip>
            <v-chip v-if="cIsAccountSetup" @click="openMagicLink = true" size="x-large" class="!border-2" variant="outlined">
                <div class="!font-inter">
                    <h2 class="!text-xs !font-medium">SET UP YOUR ACCOUNT</h2>
                    <p class="text-[10px]">Click here to customize</p>
                </div>
            </v-chip>

            <div>
                <v-menu open-on-hover font-black location="end">
                    <template v-slot:activator="{ props }">
                        <v-app-bar-nav-icon icon="$menu" color="black" v-bind="props"> </v-app-bar-nav-icon>
                    </template>

                    <v-list>
                        <v-list-item class="b-bottom-1">
                            <v-list-item-title>User name</v-list-item-title>
                            <v-list-item-subtitle>{{ userName }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <v-list-item link v-if="isPrivileged">
                            <v-list-item-title>
                                <router-link :to="`/dealer/${computedDealerId}/manage`">
                                    {{
                                        cIsAccountSetup ? 'Manage dealership account' : 'Setup your dealership account'
                                    }}</router-link
                                >
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openPolicyModal">
                            <v-list-item-title>Policy documents</v-list-item-title>
                        </v-list-item>
                        <v-list-item href="#" @click="logout" link>
                            <v-list-item-title>Log out</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <magic-link v-model="openMagicLink" />
        </template>
    </v-app-bar>
</template>
<script lang="ts">
export default {
    name: 'HeaderNav',
}
</script>

<script lang="ts" setup>
import MagicLink from '@/components/popables/MagicLink.vue'
import PolicyDocumentsModal from '@/components/popables/PolicyDocumentsModal.vue'
import logo from '@/assets/PinPoint3Logo.png'

import phoneNumbers from '@/constants/phoneNumbers'
import { useDealerStore } from '@/stores/dealer/dealer'
import { storeToRefs } from 'pinia'
import UAParser from 'ua-parser-js'
import { ComputedRef, Ref, computed, ref, markRaw } from 'vue'
import { useRoute } from 'vue-router'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'

const route = useRoute()
const dealerStore = useDealerStore()
const modal = useGlobalModal()
const searchTerm: Ref<string> = ref('')
const { cIsAccountSetup, isPrivileged, userName } = storeToRefs(dealerStore)

// navLink logic
type Page = (typeof pages)[number]
const openMagicLink: Ref<boolean> = ref(false)
const pages = ['Dashboard', 'Approvals', 'Accounts'] as const
const selectedPage: Ref<Page> = ref(getSelectedPage())

function openPolicyModal() {
    modal.openModal({
        title: 'Arivo Documents',
        dialogProps: {
            maxWidth: '800',
        },
        component: {
            name: markRaw(PolicyDocumentsModal),
        },
    })
}
function getSelectedPage(): Page {
    if (route.name === 'SelectedVehicle' || route.name === 'Inventory' || route.name === 'Account') {
        return 'Approvals'
    }
    return route.name as Page
}

// phone number logic
const computedDealerId: ComputedRef<string | number> = computed(
    () => dealerStore.dealer.id ?? (route.params.dealer_id as string),
)
let phoneNumberCopied: Ref<boolean> = ref(false)
const phoneNumber = computed(() => {
    if (dealerStore.dealer.team_id) return phoneNumbers[dealerStore.dealer.team_id]
})

function handlePhoneClick() {
    const type = getDeviceType()
    if (type === 'mobile' || !phoneNumber.value) return
    window.navigator.clipboard.writeText(phoneNumber.value.replace(/-/g, ''))
    phoneNumberCopied.value = true
    setTimeout(() => {
        phoneNumberCopied.value = false
    }, 2000)
}

function getDeviceType() {
    return new UAParser().getDevice().type
}

const logout = async () => {
    document.cookie = `pinpoint-auth-session=; Max-Age=0; path=/pinpoint/; domain=${import.meta.env.VITE_URL_PINPOINT}`
    window.location.href = `/dealer/${computedDealerId.value}/email`
}

const showSearchBar = computed(() => import.meta.env.VITE_FEATURE_SEARCHBAR === 'true')
</script>

<style scoped>
:deep(.v-chip.custom-outlined-chip.v-chip--variant-outlined) {
    border-color: rgb(var(--v-theme-primary)) !important;
}
#header-search-term .container {
    background: var(--color-gray-100);
    border-radius: 30px;
    border-color: var(--color-gray-100);
    width: 98px;
    height: 34px;
}

#header-search-term .container svg {
    color: black;
}

#header-search-term input {
    background-color: var(--color-gray-100);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 60px;
}

#header-search-term input::placeholder {
    font-style: normal;
    font-weight: 400;
    color: black;
}

@media screen and (min-width: 0px) {
    .justify-elements {
        justify-content: center;
    }
}

@media screen and (min-width: 768px) {
    .justify-elements {
        justify-content: flex-end;
    }
}
</style>
